/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { strict as t } from "../core/jsonMap.js";
import { beforeLocaleChange as e, getLocale as o } from "./locale.js";
import { system as r, utc as n, unknown as a, systemTimeZone as m, shortTimeZoneName as h } from "../time/timeZoneUtils.js";
import { DateTime as i } from "luxon";
const s = {
    year: void 0,
    month: void 0,
    day: void 0,
    weekday: void 0
  },
  l = {
    hour: void 0,
    minute: void 0,
    second: void 0
  },
  y = {
    timeZone: n
  },
  d = {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  },
  g = {
    year: "numeric",
    month: "long",
    day: "numeric"
  },
  u = {
    year: "numeric",
    month: "short",
    day: "numeric"
  },
  c = {
    year: "numeric",
    month: "long",
    weekday: "long",
    day: "numeric"
  },
  D = {
    hour: "numeric",
    minute: "numeric"
  },
  T = {
    ...D,
    second: "numeric"
  },
  f = {
    hourCycle: "h23"
  },
  S = {
    ...D,
    ...f
  },
  L = {
    ...T,
    ...f
  },
  M = {
    "short-date": d,
    "short-date-short-time": {
      ...d,
      ...D
    },
    "short-date-short-time-24": {
      ...d,
      ...S
    },
    "short-date-long-time": {
      ...d,
      ...T
    },
    "short-date-long-time-24": {
      ...d,
      ...L
    },
    "short-date-le": d,
    "short-date-le-short-time": {
      ...d,
      ...D
    },
    "short-date-le-short-time-24": {
      ...d,
      ...S
    },
    "short-date-le-long-time": {
      ...d,
      ...T
    },
    "short-date-le-long-time-24": {
      ...d,
      ...L
    },
    "long-month-day-year": g,
    "long-month-day-year-short-time": {
      ...g,
      ...D
    },
    "long-month-day-year-short-time-24": {
      ...g,
      ...S
    },
    "long-month-day-year-long-time": {
      ...g,
      ...T
    },
    "long-month-day-year-long-time-24": {
      ...g,
      ...L
    },
    "day-short-month-year": u,
    "day-short-month-year-short-time": {
      ...u,
      ...D
    },
    "day-short-month-year-short-time-24": {
      ...u,
      ...S
    },
    "day-short-month-year-long-time": {
      ...u,
      ...T
    },
    "day-short-month-year-long-time-24": {
      ...u,
      ...L
    },
    "long-date": c,
    "long-date-short-time": {
      ...c,
      ...D
    },
    "long-date-short-time-24": {
      ...c,
      ...S
    },
    "long-date-long-time": {
      ...c,
      ...T
    },
    "long-date-long-time-24": {
      ...c,
      ...L
    },
    "long-month-year": {
      month: "long",
      year: "numeric"
    },
    "short-month-year": {
      month: "short",
      year: "numeric"
    },
    year: {
      year: "numeric"
    },
    "short-time": D,
    "long-time": T
  },
  Y = t()({
    shortDate: "short-date",
    shortDateShortTime: "short-date-short-time",
    shortDateShortTime24: "short-date-short-time-24",
    shortDateLongTime: "short-date-long-time",
    shortDateLongTime24: "short-date-long-time-24",
    shortDateLE: "short-date-le",
    shortDateLEShortTime: "short-date-le-short-time",
    shortDateLEShortTime24: "short-date-le-short-time-24",
    shortDateLELongTime: "short-date-le-long-time",
    shortDateLELongTime24: "short-date-le-long-time-24",
    longMonthDayYear: "long-month-day-year",
    longMonthDayYearShortTime: "long-month-day-year-short-time",
    longMonthDayYearShortTime24: "long-month-day-year-short-time-24",
    longMonthDayYearLongTime: "long-month-day-year-long-time",
    longMonthDayYearLongTime24: "long-month-day-year-long-time-24",
    dayShortMonthYear: "day-short-month-year",
    dayShortMonthYearShortTime: "day-short-month-year-short-time",
    dayShortMonthYearShortTime24: "day-short-month-year-short-time-24",
    dayShortMonthYearLongTime: "day-short-month-year-long-time",
    dayShortMonthYearLongTime24: "day-short-month-year-long-time-24",
    longDate: "long-date",
    longDateShortTime: "long-date-short-time",
    longDateShortTime24: "long-date-short-time-24",
    longDateLongTime: "long-date-long-time",
    longDateLongTime24: "long-date-long-time-24",
    longMonthYear: "long-month-year",
    shortMonthYear: "short-month-year",
    year: "year"
  }),
  Z = {
    ar: "ar-u-nu-latn-ca-gregory"
  };
let w = new WeakMap();
const p = M["short-date-short-time"];
function v(t) {
  let e = w.get(t);
  if (!e) {
    const n = o(),
      a = Z[n] || n,
      m = F(t.timeZone ?? r),
      h = {
        ...t,
        timeZone: m
      };
    e = new Intl.DateTimeFormat(a, h), w.set(t, e);
  }
  return e;
}
function E(t) {
  return M[t];
}
function j(t, e = p) {
  return v(e).format(t);
}
function k(t, e = p) {
  return j(new Date(t), {
    ...e,
    ...y,
    ...l
  });
}
function I(t, e = p) {
  return j(new Date(`1970-01-01T${t}Z`), {
    ...e,
    ...y,
    ...s
  });
}
function x(t, e = p) {
  if (e.timeZone) return j(new Date(t), e);
  const r = i.fromISO(t, {
      setZone: !0
    }),
    a = o(),
    m = Z[a] ?? a,
    h = 0 === r.offset ? n : e.timeZone,
    s = {
      ...e,
      timeZone: h
    };
  return r.toLocaleString(s, {
    locale: m
  });
}
function F(t) {
  switch (t) {
    case r:
      return m;
    case a:
      return n;
    default:
      return t;
  }
}
function N(t, e) {
  const r = o(),
    n = Z[r] ?? r;
  return new Intl.DateTimeFormat(n, e).formatToParts(t);
}
function W(t, e = new Date()) {
  return N(e, {
    timeZone: t,
    timeZoneName: h
  }).find(({
    type: t
  }) => "timeZoneName" === t)?.value;
}
e(() => {
  w = new WeakMap();
});
export { E as convertDateFormatToIntlOptions, Y as dateFormatJSONMap, j as formatDate, k as formatDateOnly, I as formatTimeOnly, x as formatTimestamp, v as getDateTimeFormatter, W as getShortOffsetTimeZoneSuffix, F as resolveTimeZone };