/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { legacyTimeZoneMap as t } from "./legacyTimeZoneMap.js";
import { DateTime as e, FixedOffsetZone as n } from "luxon";
const o = "system",
  r = "unknown",
  i = "UTC",
  s = Intl.DateTimeFormat().resolvedOptions().timeZone,
  a = "shortOffset";
function m(t) {
  return "timeZoneIANA" in t;
}
function u(t) {
  return t.startsWith("UTC");
}
function c(t, e = !1) {
  return {
    json: {
      read: {
        source: t,
        reader: (e, n) => n[t] ? f(n[t]) : null
      },
      write: !!e && {
        allowNull: !0,
        writer(e, n) {
          n[t] = e ? l(e) : null;
        }
      }
    }
  };
}
function f(t, e = o) {
  return t ? m(t) ? t.timeZoneIANA : Z(t, e) : e;
}
function l(t) {
  return {
    timeZoneIANA: t
  };
}
function Z(e, n = o) {
  if (!e || !t.has(e.timeZone)) return n;
  const r = t.get(e.timeZone);
  return u(e.timeZone) || e.respectsDaylightSaving ? r : d(r);
}
function d(t) {
  const o = e.local().setZone(t),
    r = Math.min(o.set({
      month: 1,
      day: 1
    }).offset, o.set({
      month: 5
    }).offset);
  if (0 === r) return "Etc/UTC";
  return `Etc/GMT${n.instance(-r).formatOffset(0, "narrow")}`;
}
function g(t, e, n, o, s) {
  if (e && "date" === s) return {
    timeZone: i,
    timeZoneName: void 0
  };
  const m = o.timeStyle || o.hour,
    u = n === r;
  return {
    timeZone: u ? "timestamp-offset" === s ? void 0 : t ?? i : n,
    timeZoneName: u && m ? a : void 0
  };
}
function h(t) {
  const e = t === o ? s : t,
    n = /^(?<area>[\w-]+)(?:\/(?<region>[\w-]+))?\/(?<location>[\w+-]+)$/;
  if (n.test(e)) {
    const t = n.exec(e)?.groups,
      {
        area: o,
        region: r,
        location: i
      } = t;
    return {
      area: o,
      region: r,
      location: i,
      timeZone: e
    };
  }
  return {
    timeZone: e
  };
}
export { Z as convertLegacyTimeZone, f as fromTimeReference, h as getTimeZoneComponents, g as getTimeZoneFormattingOptions, a as shortTimeZoneName, o as system, s as systemTimeZone, c as timeZoneProperty, l as toTimeReference, r as unknown, i as utc };